var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      value: _vm.value,
      options: _vm.rootCouriers,
      reduce: (courier) => courier,
      label: "first_name",
      placeholder: "Courier..",
      searchable: true,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "selected-option",
        fn: function (courier) {
          return [
            _c(
              "div",
              { staticClass: "selected-courier" },
              [
                courier.detail?.parent_id
                  ? [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.getParentName(courier)) + " › "),
                      ]),
                      _c("span", [_vm._v("👷")]),
                      _vm._v(_vm._s(courier.first_name) + " "),
                    ]
                  : [
                      courier.detail?.is_dispatcher
                        ? _c("span", [_vm._v("👔")])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(courier.first_name) + " "),
                    ],
              ],
              2
            ),
          ]
        },
      },
      {
        key: "option",
        fn: function (courier) {
          return [
            _c("div", { staticClass: "courier-parent" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("CImg", {
                    staticClass: "courier-avatar",
                    attrs: {
                      src: courier.image_icon || courier.gravatar,
                      placeholderColor: "lightgray",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(courier.first_name) + " #" + _vm._s(courier.id)
                    ),
                  ]),
                  _vm.getChildCouriers(courier).length
                    ? _c("span", { staticClass: "ms-1" }, [_vm._v("▾")])
                    : _vm._e(),
                  courier.detail?.is_dispatcher
                    ? _c("span", { staticClass: "ms-1 dot bg-secondary" }, [
                        _vm._v("👔"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._l(_vm.getChildCouriers(courier), function (child) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "courier-child",
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleOptionSelected(child)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c("span", { staticClass: "tree-line" }, [_vm._v("├")]),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("CImg", {
                            staticClass: "courier-avatar",
                            attrs: {
                              src: child.image_icon || child.gravatar,
                              placeholderColor: "lightgray",
                            },
                          }),
                          _c("div", [
                            _c("div", [
                              _vm._v(
                                _vm._s(child.first_name) +
                                  " #" +
                                  _vm._s(child.id)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            }),
          ]
        },
      },
      {
        key: "no-options",
        fn: function () {
          return [
            _c("div", { staticClass: "text-muted p-2" }, [
              _vm._v("No couriers found"),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }