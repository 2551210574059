var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c(
                "CCardBody",
                { staticClass: "p-2 d-flex flex-column flex-gap-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-lg-row flex-column flex-gap-2",
                    },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter w-100",
                        attrs: {
                          options: _vm.orderStatus,
                          reduce: (item) => item.value,
                          searchable: false,
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedOrderStatus,
                          callback: function ($$v) {
                            _vm.selectedOrderStatus = $$v
                          },
                          expression: "selectedOrderStatus",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "input-group w-100" },
                        [
                          _c("v-select", {
                            staticClass: "v-select-filter flex-grow-1",
                            attrs: {
                              placeholder: "Order status..",
                              options: _vm.orderFeatureStatus,
                              reduce: (item) => item.value,
                              searchable: false,
                              multiple: "",
                            },
                            on: { input: _vm.getAllItems },
                            model: {
                              value: _vm.selectedOrderFeatureStatus,
                              callback: function ($$v) {
                                _vm.selectedOrderFeatureStatus = $$v
                              },
                              expression: "selectedOrderFeatureStatus",
                            },
                          }),
                          _vm.selectedOrderFeatureStatus?.length >= 2
                            ? _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-text p-0" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.orderFeatureStatusCondition,
                                            expression:
                                              "orderFeatureStatusCondition",
                                          },
                                        ],
                                        staticClass:
                                          "form-control font-weight-bold p-0 ml-1",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.orderFeatureStatusCondition =
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                            },
                                            _vm.getAllItems,
                                          ],
                                        },
                                      },
                                      [
                                        _c("option", [_vm._v("AND")]),
                                        _c("option", [_vm._v("OR")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-select", {
                        staticClass: "v-select-filter w-100",
                        attrs: {
                          placeholder: "Order channel..",
                          options: [
                            { value: "0", label: "MS" },
                            { value: "1", label: "SW" },
                            { value: "2", label: "APP" },
                          ],
                          reduce: (o) => o.value,
                          searchable: false,
                          multiple: "",
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedOrderChannel,
                          callback: function ($$v) {
                            _vm.selectedOrderChannel = $$v
                          },
                          expression: "selectedOrderChannel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-lg-row flex-column flex-gap-2",
                    },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter w-100",
                        attrs: {
                          placeholder: "Payment methods..",
                          options: [
                            { value: "MPY", label: "Menute Pay" },
                            { value: "AE", label: "American Express" },
                            { value: "AP", label: "AP" },
                            { value: "app", label: "app" },
                            { value: "APY", label: "Apple Pay" },
                            { value: "BC", label: "Bitcoin" },
                            {
                              value: "BK",
                              label: "Bancontact / Mister Cash (BK)",
                            },
                            {
                              value: "BKS",
                              label: "Bancontact / Mister Cash (BKS)",
                            },
                            { value: "CC", label: "Credit Card" },
                            { value: "CNT", label: "Contant" },
                            { value: "GPY", label: "Google Pay" },
                            { value: "IDL", label: "iDEAL" },
                            { value: "MC", label: "MasterCard" },
                            { value: "PP", label: "PayPal" },
                            { value: "SOD", label: "Sodexo" },
                            { value: "VCR", label: "Sodexo Monizze" },
                            { value: "VS", label: "Visa" },
                          ],
                          reduce: (p) => p.value,
                          searchable: false,
                          multiple: "",
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedPaymentMethod,
                          callback: function ($$v) {
                            _vm.selectedPaymentMethod = $$v
                          },
                          expression: "selectedPaymentMethod",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "v-select-filter w-100",
                        attrs: {
                          placeholder: "Select restaurant..",
                          options: _vm.restaurants,
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedRestaurant,
                          callback: function ($$v) {
                            _vm.selectedRestaurant = $$v
                          },
                          expression: "selectedRestaurant",
                        },
                      }),
                      _c(
                        "v-select",
                        {
                          staticClass: "v-select-filter w-100",
                          attrs: {
                            placeholder:
                              _vm.users.length == 0
                                ? "Search for a user.."
                                : "Select or search for a user..",
                            options: _vm.users,
                            searchable: true,
                            loading: _vm.searching && _vm.users.length == 0,
                          },
                          on: {
                            search: _vm.searchUsers,
                            input: _vm.getAllItems,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (user) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "c-avatar mr-2" },
                                        [
                                          _c("img", {
                                            staticClass: "c-avatar-img",
                                            attrs: {
                                              src:
                                                user.image_icon ||
                                                user.gravatar,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("span", { staticClass: "small" }, [
                                        _c("strong", [
                                          _vm._v(_vm._s(user.first_name)),
                                        ]),
                                        _vm._v(
                                          " #" +
                                            _vm._s(user.id) +
                                            " (" +
                                            _vm._s(user.email) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (user) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(user.first_name) +
                                      " #" +
                                      _vm._s(user.id) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.selectedUser,
                            callback: function ($$v) {
                              _vm.selectedUser = $$v
                            },
                            expression: "selectedUser",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v("Type to search users..")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-lg-row flex-column flex-gap-2",
                    },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter w-100",
                        attrs: {
                          placeholder: "🚚 Delivery service..",
                          options: [
                            { value: "all", label: "All" },
                            {
                              value: "not-confirmed",
                              label: "Order not confirmed",
                            },
                            {
                              value: "pending-assignment",
                              label: "Pending assignment",
                            },
                            { value: "not-assigned", label: "Not assigned" },
                            {
                              value: "not-confirmed-by-courier",
                              label: "Not confirmed by courier",
                            },
                            { value: "on-delivery", label: "On delivery" },
                          ],
                          reduce: (o) => o.value,
                          searchable: false,
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.deliveryServiceStatus,
                          callback: function ($$v) {
                            _vm.deliveryServiceStatus = $$v
                          },
                          expression: "deliveryServiceStatus",
                        },
                      }),
                      _c("CourierSelect", {
                        staticClass: "v-select-filter w-100",
                        attrs: { couriers: _vm.couriers },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedCourier,
                          callback: function ($$v) {
                            _vm.selectedCourier = $$v
                          },
                          expression: "selectedCourier",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "v-select-filter w-100",
                        attrs: {
                          placeholder: "Referrer params..",
                          options: [
                            { value: "eo", label: "EO (Google Ads)" },
                            { value: "mt", label: "MT (Google Ads in Maps)" },
                            { value: "dc", label: "DC (Display Campaign)" },
                          ],
                          reduce: (o) => o.value,
                          searchable: false,
                          multiple: "",
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedReferrerParams,
                          callback: function ($$v) {
                            _vm.selectedReferrerParams = $$v
                          },
                          expression: "selectedReferrerParams",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-lg-row flex-column flex-gap-2",
                    },
                    [
                      _c("v-date-picker", {
                        staticClass: "date-range w-100",
                        attrs: {
                          "is-range": "",
                          "model-config": {
                            type: "string",
                            mask: "YYYY-MM-DD",
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ inputValue, inputEvents }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-center",
                                  },
                                  [
                                    _c(
                                      "input",
                                      _vm._g(
                                        {
                                          staticClass: "form-control",
                                          attrs: { placeholder: "Start" },
                                          domProps: { value: inputValue.start },
                                        },
                                        inputEvents.start
                                      )
                                    ),
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          fill: "none",
                                          viewBox: "0 0 24 24",
                                          stroke: "currentColor",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "2",
                                            d: "M14 5l7 7m0 0l-7 7m7-7H3",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "input",
                                        _vm._g(
                                          {
                                            staticClass: "form-control",
                                            attrs: { placeholder: "End" },
                                            domProps: { value: inputValue.end },
                                          },
                                          inputEvents.end
                                        )
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "input-group-append" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-group-text px-2",
                                              attrs: { role: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.date = null
                                                },
                                              },
                                            },
                                            [_vm._v("❌")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                      _c("CInput", {
                        staticClass: "w-200 mb-0 search",
                        attrs: {
                          type: "search",
                          placeholder: "Search for something else ...",
                        },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.progressDown
        ? _c("CProgress", { attrs: { value: _vm.progress, size: "sm" } })
        : _vm._e(),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No orders found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: item.restaurant._hr_datetime
                                ? item.restaurant._hr_datetime
                                : "-",
                              placement: "right",
                            },
                            expression:
                              "{\n            html: true,\n            content: item.restaurant._hr_datetime ? item.restaurant._hr_datetime : '-',\n            placement: 'right'\n          }",
                          },
                        ],
                        staticClass: "rounded p-2 font-weight-bold",
                        class: _vm.getRestaurantConnectionStatus(
                          item.restaurant.restaurant_device_link_with_device
                        ),
                        attrs: {
                          to: {
                            name: "Order Details",
                            params: { id: item.id },
                          },
                        },
                      },
                      [_vm._v(" #" + _vm._s(item.id) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "confirmation",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-gap-2 align-items-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          item.roUnderControl == 1
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        content: item.control_taked_by,
                                        placement: "top",
                                      },
                                      expression:
                                        "{ content: item.control_taked_by, placement: 'top' }",
                                    },
                                  ],
                                  staticClass: "flash-it",
                                  staticStyle: { "font-size": "1.875rem" },
                                },
                                [_vm._v("👁️")]
                              )
                            : _vm._e(),
                          item.roConfirmationTime
                            ? _c("CIcon", {
                                directives: [
                                  {
                                    name: "c-tooltip",
                                    rawName: "v-c-tooltip",
                                    value: {
                                      html: true,
                                      content: item.roConfirmationTime,
                                    },
                                    expression:
                                      "{\n                html: true,\n                content: item.roConfirmationTime\n              }",
                                  },
                                ],
                                staticClass: "text-success",
                                attrs: { height: 32, name: "cil-check-alt" },
                              })
                            : _c(
                                "CLink",
                                {
                                  attrs: {
                                    to: {
                                      name: "Order Details",
                                      params: { id: item.id },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    staticClass: "text-warning",
                                    attrs: { height: 32, name: "cil-x-circle" },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("span", { staticClass: "d-inline count bg-dark" }, [
                          _vm._v(_vm._s(item._order_attr)),
                        ]),
                        _c("span", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(item.roStrId)),
                        ]),
                        _c("span", { staticClass: "d-block" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.roPymntVfd == true ? "" : "* ") +
                              " " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  item.roTotalPrice > 0 ? item.roTotalPrice : 0
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "badge border border-dark" },
                          [_vm._v(_vm._s(item.roPaysWith))]
                        ),
                        item.roPaysWith2
                          ? _c(
                              "span",
                              { staticClass: "badge border border-dark ml-1" },
                              [_vm._v(_vm._s(item.roPaysWith2))]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
              ]
            },
          },
          {
            key: "restaurant",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c("span", {
                      staticClass: "badge border border-dark",
                      domProps: {
                        innerHTML: _vm._s(_vm.checkDeviceType(item.restaurant)),
                      },
                    }),
                    _vm._v(" #" + _vm._s(item.restaurant_id) + " "),
                    _c(
                      "CLink",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          target: "_blank",
                          to: {
                            name: "View Restaurant",
                            params: { id: item.restaurant_id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(item.restaurant.restaurant_name) + " "
                        ),
                      ]
                    ),
                    _vm._v(
                      ", " + _vm._s(item.restaurant.restaurant_city) + " "
                    ),
                    _c("div", { staticClass: "restaurant-tel" }, [
                      item.restaurant.restaurant_tel
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "tel://" + item.restaurant.restaurant_tel,
                              },
                            },
                            [_vm._v(_vm._s(item.restaurant.restaurant_tel))]
                          )
                        : _vm._e(),
                      item.restaurant.restaurant_gsm
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "tel://" + item.restaurant.restaurant_gsm,
                              },
                            },
                            [_vm._v(_vm._s(item.restaurant.restaurant_gsm))]
                          )
                        : _vm._e(),
                      item.restaurant.eigenaar1_gsm
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "tel://" + item.restaurant.eigenaar1_gsm,
                              },
                            },
                            [_vm._v(_vm._s(item.restaurant.eigenaar1_gsm))]
                          )
                        : _vm._e(),
                      item.restaurant.eigenaar2_gsm
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "tel://" + item.restaurant.eigenaar2_gsm,
                              },
                            },
                            [_vm._v(_vm._s(item.restaurant.eigenaar2_gsm))]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: {
                            content: "Restaurant Opening Time",
                            placement: "left",
                          },
                          expression:
                            "{ content: 'Restaurant Opening Time', placement: 'left'}",
                        },
                      ],
                      staticClass: "restaurant-opening-time",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getRestaurantOpeningTime(item.restaurant)
                        ),
                      },
                    }),
                    _c("div", {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: {
                            content: "Restaurant Delivery Time",
                            placement: "left",
                          },
                          expression:
                            "{ content: 'Restaurant Delivery Time', placement: 'left'}",
                        },
                      ],
                      staticClass: "restaurant-delivery-time",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getRestaurantDeliveryTime(item.restaurant)
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "total_price",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(item.roPymntVfd == true ? "" : "* ") +
                      " " +
                      _vm._s(
                        _vm._f("toCurrency")(
                          item.roTotalPrice > 0
                            ? item.roTotalPrice - item.roHandlingfee
                            : 0
                        )
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "badge border border-dark" }, [
                    _vm._v(_vm._s(item.roPaysWith)),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "created_at-filter",
            fn: function () {
              return [
                _c("div", { staticClass: "input-group mb-1" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text p-1 w-min" }, [
                      _vm._v("From"),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.orders.startDate,
                        expression: "filters.orders.startDate",
                      },
                    ],
                    staticClass: "w-min",
                    attrs: { id: "date-filter-from", type: "datetime-local" },
                    domProps: { value: _vm.filters.orders.startDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.orders,
                          "startDate",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text p-1 w-min" }, [
                      _vm._v("To"),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.orders.endDate,
                        expression: "filters.orders.endDate",
                      },
                    ],
                    staticClass: "w-min",
                    attrs: { id: "date-filter-from", type: "datetime-local" },
                    domProps: { value: _vm.filters.orders.endDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.orders,
                          "endDate",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "roAproxDeliveryTime",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("dl", { staticClass: "mb-0" }, [
                    _c("dt", [_vm._v(_vm._s(item.created_at))]),
                    _c("dt", { staticClass: "font-weight-normal" }, [
                      _vm._v("Desired: "),
                      _c("strong", [
                        _vm._v(_vm._s(item.roDesiredDelivireyTime)),
                      ]),
                    ]),
                    _c("dt", { staticClass: "font-weight-normal" }, [
                      _vm._v("Approximate: "),
                      _c("strong", [
                        _vm._v(_vm._s(item.roAproxDeliveryTime || "∅")),
                      ]),
                    ]),
                    item.deliveryservice
                      ? _c("dt", { staticClass: "font-weight-normal" }, [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-gap-2 border-top mt-1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column flex-gap-2 align-items-center",
                                },
                                [
                                  item.deliveryServiceOrderStatus?.status
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "c-tooltip",
                                              rawName: "v-c-tooltip",
                                              value: {
                                                content:
                                                  item
                                                    .deliveryServiceOrderStatus
                                                    .message,
                                                placement: "bottom",
                                              },
                                              expression:
                                                "{ content: item.deliveryServiceOrderStatus.message, placement: 'bottom' }",
                                            },
                                          ],
                                          staticClass: "flash-it",
                                          staticStyle: { "font-size": "2rem" },
                                        },
                                        [_vm._v("⛔")]
                                      )
                                    : item.delivery?.courier
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-1 c-avatar" },
                                        [
                                          _c("img", {
                                            staticClass: "c-avatar-img",
                                            attrs: {
                                              src:
                                                item.delivery.courier
                                                  ?.image_icon ||
                                                item.delivery.courier?.gravatar,
                                            },
                                          }),
                                        ]
                                      )
                                    : item.delivery?.dispatcher
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mt-1 c-avatar bg-secondary",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "1.375rem",
                                              },
                                            },
                                            [_vm._v("👔")]
                                          ),
                                        ]
                                      )
                                    : _c("CIcon", {
                                        staticClass: "text-dark",
                                        attrs: {
                                          name: "cil-truck",
                                          size: "2xl",
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c("div", [
                                !(
                                  item.roConfirmationTime &&
                                  item.delivery?.created_at_db
                                )
                                  ? _c("div", [
                                      !item.roConfirmationTime
                                        ? _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "text-warning flash-it",
                                            },
                                            [_vm._v("Not confirmed yet!")]
                                          )
                                        : !item.delivery?.created_at_db
                                        ? _c(
                                            "div",
                                            [
                                              new Date(
                                                item.roAproxDeliveryDateToCourier
                                              ) < new Date()
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "text-danger flash-it",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Not yet assigned! Must be assigned by "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$globalFunc.formatDateTime(
                                                              item.roAproxDeliveryDateToCourier
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass: "text-info",
                                                    },
                                                    [
                                                      _vm._v(" On "),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$globalFunc.formatDateTime(
                                                              item.roAproxDeliveryDateToCourier
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " will be assigned to the courier "
                                                      ),
                                                    ]
                                                  ),
                                              _c(
                                                "CLink",
                                                {
                                                  staticClass:
                                                    "d-block small mt-1",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.assignToCourier(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " • Assign to courier"
                                                  ),
                                                  _c("CIcon", {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      name: "cil-transfer",
                                                      size: "sm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center flex-gap-1",
                                        },
                                        [
                                          _c(
                                            "small",
                                            {
                                              class: {
                                                "text-warning":
                                                  item.courierCannotBeAssigned,
                                                "text-muted":
                                                  !item.courierCannotBeAssigned,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "• Courier cannot be assigned:"
                                              ),
                                            ]
                                          ),
                                          _c("CSwitch", {
                                            attrs: {
                                              labelOn: "Yes",
                                              labelOff: "No",
                                              color: "warning",
                                              size: "sm",
                                              shape: "pill",
                                              checked:
                                                item.courierCannotBeAssigned,
                                            },
                                            on: {
                                              "update:checked": [
                                                function ($event) {
                                                  return _vm.$set(
                                                    item,
                                                    "courierCannotBeAssigned",
                                                    $event
                                                  )
                                                },
                                                () =>
                                                  _vm.updateDeliveryServiceDetails(
                                                    item
                                                  ),
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "order-courier-container text-success",
                                      },
                                      [
                                        item.delivery?.courier
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "delivery-details",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        ((_vm.nearestDeliveryEvent =
                                                          _vm.getNearestDeliveryEvent(
                                                            item.delivery
                                                          )),
                                                        null)
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.nearestDeliveryEvent
                                                          ?.key
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    { staticClass: "d-block" },
                                                    [
                                                      _vm._v(
                                                        "📆 " +
                                                          _vm._s(
                                                            _vm.nearestDeliveryEvent?.value.toLocaleString()
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  !item.delivery.confirmed
                                                    ? _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "text-danger flash-it",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Not yet confirmed by " +
                                                              _vm._s(
                                                                item.delivery
                                                                  .courier
                                                                  ?.first_name
                                                              ) +
                                                              " #" +
                                                              _vm._s(
                                                                item.delivery
                                                                  .courier?.id
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "courier-details",
                                                },
                                                [
                                                  _c("span", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.delivery.courier
                                                            ?.first_name
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " #" +
                                                        _vm._s(
                                                          item.delivery.courier
                                                            ?.id
                                                        )
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `mailto:${item.delivery.courier?.email}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.delivery
                                                              .courier?.email
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v("📱"),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `tel:${item.delivery.courier?.mobile}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.delivery
                                                              .courier?.mobile
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : item.delivery?.dispatcher &&
                                            !item.delivery?.courier
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "delivery-details",
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Assigned to dispatcher " +
                                                        _vm._s(
                                                          item.delivery
                                                            .dispatcher
                                                            .first_name
                                                        ) +
                                                        " (#" +
                                                        _vm._s(
                                                          item.delivery
                                                            .dispatcher.id
                                                        ) +
                                                        ", 🕒" +
                                                        _vm._s(
                                                          _vm.$globalFunc.formatTime(
                                                            item.delivery
                                                              .created_at_db
                                                          )
                                                        ) +
                                                        ") "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "d-block text-danger",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "flash-it",
                                                        },
                                                        [_vm._v("⌛")]
                                                      ),
                                                      _vm._v(
                                                        " Waiting for courier assignment by dispatcher... "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "courier-details",
                                                },
                                                [
                                                  _c("span", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.delivery
                                                            .dispatcher
                                                            .first_name
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " #" +
                                                        _vm._s(
                                                          item.delivery
                                                            .dispatcher.id
                                                        )
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `mailto:${item.delivery.dispatcher.email}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.delivery
                                                              .dispatcher.email
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v("📱"),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `tel:${item.delivery.dispatcher.mobile}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.delivery
                                                              .dispatcher.mobile
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "user_id",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c(
                    "div",
                    [
                      item.user
                        ? [
                            item.user.lang == "en"
                              ? _c("CIcon", {
                                  staticClass: "flag",
                                  attrs: {
                                    width: 24,
                                    content: _vm.$options.flagSet["cifGb"],
                                  },
                                })
                              : _c("CIcon", {
                                  staticClass: "flag",
                                  attrs: {
                                    height: 14,
                                    content:
                                      _vm.$options.flagSet[
                                        "cif" +
                                          item.user.lang
                                            .charAt(0)
                                            .toUpperCase() +
                                          item.user.lang.slice(1)
                                      ],
                                  },
                                }),
                            _vm._v(
                              " " + _vm._s(item.user.lang.toUpperCase()) + " "
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "CLink",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            to: {
                              name: "UserOrderList",
                              params: { user_id: item.user_id },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " #" +
                              _vm._s(item.user_id) +
                              " " +
                              _vm._s(item.roUseName) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        { staticClass: "d-inline count bg-success px-1 mr-1" },
                        [
                          _vm._v(
                            _vm._s(
                              item.roOrdersBefore == 0
                                ? "N"
                                : item.roOrdersBefore
                            )
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                  _c("dl", { staticClass: "mt-2 mb-0" }, [
                    _c(
                      "dt",
                      { staticClass: "font-weight-normal" },
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-at" },
                        }),
                        _vm._v(
                          _vm._s(item.user ? item.user.email : "User deleted.")
                        ),
                      ],
                      1
                    ),
                    _c(
                      "dt",
                      { staticClass: "font-weight-normal" },
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-mobile" },
                        }),
                        _vm._v(_vm._s(item.roMobile)),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "postal_code",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("dl", [
                    _c(
                      "dt",
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-location-pin" },
                        }),
                        _vm._v(
                          _vm._s(item.roPostal_code) +
                            " (" +
                            _vm._s(item.roDistance) +
                            ") " +
                            _vm._s(item.roCity)
                        ),
                      ],
                      1
                    ),
                    _c("dd", { staticStyle: { "margin-left": "20px" } }, [
                      _vm._v(_vm._s(item.roAddress)),
                    ]),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "_status",
            fn: function ({ item }) {
              return [
                _c("td", [
                  item._status
                    ? _c(
                        "ul",
                        { staticClass: "mb-0 pl-0" },
                        _vm._l(
                          item._status.filter((s) => s !== null),
                          function (status) {
                            return _c("li", { key: status }, [
                              _vm._v(" " + _vm._s(status) + " "),
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "referer",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("ul", { staticClass: "mb-0 pl-0 tile" }, [
                    _c(
                      "li",
                      [
                        _c("CImg", {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: {
                                html: true,
                                content: item._is_mobile[2],
                              },
                              expression:
                                "{\n                html: true,\n                content: item._is_mobile[2]\n              }",
                            },
                          ],
                          attrs: { src: _vm.refererIcon(item._is_mobile[2]) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("CImg", {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: {
                                html: true,
                                content: item.roReferrer,
                              },
                              expression:
                                "{\n                html: true,\n                content: item.roReferrer\n              }",
                            },
                          ],
                          attrs: { src: "/img/icons/link-referer.png" },
                        }),
                      ],
                      1
                    ),
                    item.roMedium == 1
                      ? _c(
                          "li",
                          [
                            _c("CImg", {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: {
                                    html: true,
                                    content: item.subweb_url,
                                  },
                                  expression:
                                    "{\n                html: true,\n                content: item.subweb_url\n              }",
                                },
                              ],
                              attrs: { src: "/img/icons/sub-web.png" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-items-center flex-gap-2" },
              [
                _c(
                  "div",
                  {
                    staticClass: "row text-center",
                    staticStyle: { "min-width": "320px" },
                  },
                  [
                    _c("div", { staticClass: "col-3" }, [
                      _c("div", { staticClass: "text-value-lg" }, [
                        _c(
                          "span",
                          { staticClass: "d-inline count px-1 bg-primary" },
                          [_vm._v(_vm._s(_vm.total))]
                        ),
                      ]),
                      _c("div", { staticClass: "text-value-sm text-success" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              parseFloat(_vm.totalPriceVerified)
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-uppercase small text-danger" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                parseFloat(_vm.totalPriceDeleted)
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm.statistics
                      ? [
                          _c("div", { staticClass: "c-vr" }),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "text-value-lg" }, [
                              _vm._v(_vm._s(_vm.statistics.orders.today.count)),
                            ]),
                            _c("div", { staticClass: "text-value-sm" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    parseFloat(
                                      _vm.statistics.orders.today.total_price
                                    )
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "text-uppercase text-muted small",
                              },
                              [_vm._v("Today")]
                            ),
                          ]),
                          _c("div", { staticClass: "c-vr" }),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "text-value-lg" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.statistics.orders.today_deleted.count
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "text-uppercase text-muted small",
                              },
                              [_vm._v("Today Deleted")]
                            ),
                          ]),
                          _c("div", { staticClass: "c-vr" }),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "text-value-lg" }, [
                              _vm._v(
                                _vm._s(_vm.statistics.orders.yesterday.count)
                              ),
                            ]),
                            _c("div", { staticClass: "text-value-sm" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    parseFloat(
                                      _vm.statistics.orders.yesterday
                                        .total_price
                                    )
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "text-uppercase text-muted small",
                              },
                              [_vm._v("Yesterday")]
                            ),
                          ]),
                          _c("div", { staticClass: "c-vr" }),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "text-value-lg" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.statistics.orders.yesterday_deleted.count
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "text-uppercase text-muted small",
                              },
                              [_vm._v("Yesterday Deleted")]
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("CPagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pages > 1,
                          expression: "pages > 1",
                        },
                      ],
                      attrs: {
                        align: "center",
                        dots: false,
                        pages: _vm.pages,
                        "active-page": _vm.activePage,
                      },
                      on: {
                        "update:activePage": function ($event) {
                          _vm.activePage = $event
                        },
                        "update:active-page": function ($event) {
                          _vm.activePage = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("TransferOrRevokeOrder", {
        attrs: {
          courierId: null,
          order: _vm.$globalFunc.extractKeys(_vm.selectedOrder, [
            "id",
            "roDesiredDelivireyTime",
            "roAproxDeliveryDateToCourier",
            "roAddress",
            "roCity",
            "roPostal_code",
            "roCountryId",
          ]),
          restaurant: _vm.$globalFunc.extractKeys(
            _vm.selectedOrder?.restaurant,
            ["id", "restaurant_postcode"]
          ),
        },
        on: { transferred: _vm.onTableChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }